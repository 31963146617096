import { fromJS, Record } from 'immutable';
import { z } from 'zod';

import { KioskEmployeeExportData } from '@peakon/shared/schemas/api/employeeKioskExport';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Schedule from './ScheduleRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class EmployeeKioskScheduleRecord
  extends Record({
    id: undefined,
    count: undefined,
    schedule: undefined,
  })
  implements Schema
{
  id!: Schema['id'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'EmployeeKioskScheduleRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'EmployeeKioskScheduleRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(kioskSchedule: KioskEmployeeExportData) {
    const {
      id,
      attributes: { count },
      relationships: { schedule },
    } = kioskSchedule;

    return new EmployeeKioskScheduleRecord(
      fromJS({
        id,
        count,
        schedule: Schedule.createFromApi(schedule),
      }),
    );
  }
}
